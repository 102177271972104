import React from "react"
import styles from "./index.module.scss"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Explore from "../../PageComponents/Home/Explore"
import { initVimeoPlayerWithControl, initVimeoPlayer } from "../../utils"
import loadStates from "../../../hocs/loadStates"

function Article({ images, homeContent, lang: { locale } }) {
  const isChinese = locale === "tc"

  const video = React.useRef(null)
  React.useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/484358409/1bba15b894",
        mobileVid: "https://vimeo.com/484322222/2096f3eb55",
      })
  }, [])
  
  return (
    <StaticQuery
      query={graphql`
        query BMWProfessional5PageQuery {
          image1: file(name: { eq: "BMW_90sec_EP05_Rental_1" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2: file(name: { eq: "BMW_90sec_EP05_Rental_Split" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3: file(name: { eq: "BMW_90sec_EP05_Rental_3" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image4: file(name: { eq: "BMW_90sec_EP05_Rental_6" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <section>
          <div ref={video}></div>
            {/* <header className={`${styles.header} relative overflow-hidden`}>
              <div className="bg-container bg-container--with-filter -z-10 absolute top-0 left-0 w-full h-full">
                <BackgroundImage
                  Tag="div"
                  className="w-full h-full"
                  fluid={data.image1.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  loading="eager"
                  critical={true}
                />
              </div>
            </header> */}
            <div>
              <div className="xxl:pt-32 md:pb-20 xxl:pb-40 pt-20 pb-32">
                <div className="md:w-full md:px-12 xxl:px-56 w-11/12 pl-40">
                  <h2 className="quotes md:text-6xl bold-font mb-20">
                    90-Second with BMW Professional Vol. 5: BMW Rental Service
                  </h2>
                  {isChinese ? (
                    <p className="title md:text-4xl mb-20">
                      相信不少人也會選擇以自駕遊的方式到外地旅遊，而你有沒有想過在香港也可以租車?
                    </p>
                  ) : (
                    <p className="large-text md:text-4xl mb-10">
                      A rental car is a great transportation choice for a
                      vacation. Have you ever thought of renting a car in Hong
                      Kong?
                    </p>
                  )}
                  <p className="large-text md:text-4xl md:mb-0">
                    {isChinese
                      ? "第 5 集 90-Second with BMW Professional 讓你以 90 秒時間，了解 BMW Rental Service。"
                      : "In episode 5 of 90-second with BMW Professional, we introduce you about the BMW Rental Service. No matter short-term or long-term rental, BMW Rental Service provides you with a series of BMW cars to choose from with professional services."}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="xxl:pt-32 md:pb-20">
            <div className="md:flex-col md:px-0 xxl:pb-0 xxl:px-56 flex px-40">
              <div className="xxl:w-4/12 xxl:pr-0 md:hidden w-5/12 pr-20">
                <div className="image-container xxl:mb-40 mb-20">
                  <Img fluid={data.image1.childImageSharp.fluid} />
                </div>
              </div>
              <div className="md:w-full xxl:w-8/12 xxl:px-40 xxl:pt-32 w-7/12 pt-20">
                <div className="md:pl-12 md:w-full md:mb-20 block mb-40">
                  <h3 className="quotes md:text-5xl bold-font mb-12">
                    {isChinese
                      ? "無論短期或長期租賃， BMW Rental Service 都為你提供一系列 BMW 的車款， 由專業人員為你作出詳盡介紹，亦為你提供多元化的租賃組合，切合你的不同需要。"
                      : "We offer you diversified rental portfolio to meet your needs as well."}
                  </h3>
                </div>
                <div className="image-container md:block md:pl-0 hidden pl-32 mb-20">
                  <Img fluid={data.image1.childImageSharp.fluid} />
                </div>
                <div className="xxl:w-10/12 md:w-10/12 md:pl-12 block w-11/12">
                  <h4 className="headline light-font md:text-5xl md:mb-20 md:mt-4 mb-32">
                    {isChinese
                      ? "短期租賃分別為 14 天或 1 個月，而長期租賃即有 24 個月或 36 個月的選擇。"
                      : "Short-term rental service offers period of 14-day or 1 month, while long-term rental service offers contract for 24-month or 36-month."}
                  </h4>
                  <p className="large-text mb-20">
                    {isChinese
                      ? "短期租賃方案有不同車型供你選擇，例如 BMW 首次發佈的全新四門轎跑 BMW THE 2 Gran Coupe，皇牌房車 BMW THE 3 和 BMW 開篷跑車 Z4。"
                      : "There are different vehicles from short-term rental programs to choose from, for instance the new BMW THE 2 Gran Coupe, Sport sedan BMW THE 3 series and the BMW Z4 convertible."}
                  </p>
                  <p className="large-text mb-20">
                    {isChinese
                      ? "BMW THE 2 Gran Coupe 更是 BMW 今年首度推出的車款，而透過 Rental Service，你便可以一嘗擁有它的滋味。若果想找一部車用作慶祝特別節日，或是臨時需要一部車以作替換，相信短期租賃會是一個適合你的選擇。"
                      : "BMW The 2 Gran Coupe new launched in this year, and through BMW Rental Service, you can have a taste of driving it. If you are looking for a private space for anniversary and festive celebration, or you need a temporary replacement car to support your continuous mobility, we believe that short-term rental will be a suitable choice for you."}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="md:-mt-20 flex flex-col mt-40">
            <div className="md:px-0 xxl:px-56 md:mt-20 flex px-40">
              <div className="md:w-full md:px-12 xxl:pr-0 w-6/12 pr-20">
                <h3 className="quotes md:text-5xl bold-font mb-12">
                  {isChinese
                    ? "BMW Rental Service 更另設有先租後買的方案提供，令你可以享受租金回贈來購買汽車。"
                    : "BMW Rental Service also provides a rent-and-own program, where you can enjoy rental rebates to purchase a car."}
                </h3>
              </div>
            </div>
            <div className="md:mt-20">
              <div className="image-container">
                <Img fluid={data.image2.childImageSharp.fluid} />
              </div>
            </div>
          </section>
          <section className="xxl:py-64 md:pt-0 md:px-12 py-40">
            <div className="xxl:mt-32 flex justify-center mt-20">
              <div className="md:w-full md:text-left w-8/12 text-center">
                <p className="large-text light-font mb-20">
                  {isChinese
                    ? "而長期租賃方面，租賃方式帶給你無憂的體驗。每月只需要繳付固定租金，同時亦可減低車輛管理支出，例如保險費和維修保養的費用等等。"
                    : "As for long-term rental, it is a smarter way of ownership where you can allocate your resources more wisely. You only need to pay a fixed monthly rental that includes most of the expenses incurred from the usage of vehicle, such as insurance premiums and maintenance fee."}
                </p>
                <p className="large-text light-font">
                  {isChinese
                    ? "很多公司客戶和短暫來港工作人士都會選擇長期租賃服務。BMW Rental Service 提供多種車型包括豪華房車 BMW THE 7，X6，X7 等等。"
                    : "Many corporate clients and temporary workers in Hong Kong will choose long-term rental service. BMW Rental Service provides a range of luxurious vehicle including BMW THE 7, X6, X7 and more."}
                </p>
              </div>
            </div>
          </section>
          <section className="xxl:pt-40 xxl:pb-40 pt-20 pb-20">
            <div className="md:px-12 flex justify-start px-40">
              <div className="md:w-full w-7/12">
                <h3 className="quotes md:text-5xl bold-font mb-20">
                  {isChinese
                    ? "於 BMW 豪華旗艦車系陳列室帶給你最佳的顧客體驗。"
                    : "You can enjoy the extraordinary customer experience at our flagship showroom and vehicle delivery room."}
                </h3>
              </div>
            </div>
            <div className="image-container md:px-12 px-40">
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
          </section>
          <section className="md:pt-10 xxl:py-56 md:px-12 pt-32">
            <div className="flex">
              <div className="xxl:w-7/12 md:w-full xxl:px-56 xxl:mb-40 md:pl-0 w-8/12 pl-40 mb-32">
                <p className="large-text light-font mb-8">
                  {isChinese
                    ? "組合靈活彈性，根據你的需要和預算而度身訂造多元化的租賃組合。租車過程以客人需要為先，務求提供完整的一站式體驗。"
                    : "We tailor made the rental agreement for you according to your vehicle preference, contract length and monthly budget, offering you a hassle free experience."}
                </p>
              </div>
            </div>
          </section>
          <section className="xxl:pb-56 md:pb-10 pb-40">
            <div className="xxl:px-40 md:px-12 flex justify-end pr-32">
              <div className="xxl:w-5/12 md:w-full w-6/12">
                <h3 className="quotes md:text-5xl bold-font mb-12">
                  {isChinese
                    ? "BMW Rental Service 的網站現已正式登場!"
                    : "The BMW Rental Service website is officially launched! "}
                </h3>
              </div>
              <div className="xxl:w-1/12"></div>
            </div>
          </section>
          <div className="image-container md:mt-0 relative mt-20">
            <div className="flex justify-center my-20">
              <div className={``}>
                <a
                  href={
                    isChinese
                      ? "https://news.bmwhk.com/tc/rental"
                      : "https://news.bmwhk.com/en/rental"
                  }
                  target="_blank"
                  className="bg-brand-blue xxl:w-auto xxl:px-20 xxl:py-6 hover:-translate-y-4 md:mb-8 z-10 block px-12 py-4 mb-16 text-center text-white whitespace-no-wrap transition-all duration-100 ease-linear transform border"
                >
                  {isChinese
                    ? "立即瀏覽網站了解更多!"
                    : "Explore and learn more NOW!"}
                </a>
              </div>
            </div>
            <Img fluid={data.image3.childImageSharp.fluid} />
          </div>
          <div className="border-t border-b border-white border-solid">
            <Explore images={images} home={homeContent} />
          </div>
        </div>
      )}
    />
  )
}

export default loadStates(Article)
